import React from "react"

// import app components
import Head from "components/head"
import Login from "features/authentication/components/Login"

const Page = () => {
  return (
    <div>
      <Head title="Login" description="Parallel Domain Login" />
      <Login />
    </div>
  )
}

Page.auth = false
Page.showNavbar = false

export default Page
